/**
 * Return a array with grouped arrays. Size of grouped array is based on itemsPerPage
 * @param {array} items - The array you want to reduce
 * @param {number} itemsPerPage - Size of grouped arrays
 */
// eslint-disable-next-line function-paren-newline
const groupItems = (items: JSX.Element[] | undefined, itemsPerPage: number) => {
  if (!items) {
    return []
  }

  const groups = []
  let group = []

  for (const [index, item] of items.entries()) {
    if (index % itemsPerPage === 0 && index !== 0) {
      groups.push(group)
      group = []
    }

    group.push(item)
  }

  groups.push(group)

  return groups
}

export default groupItems
