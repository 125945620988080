const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

// This function splits the string returned by Dato, and returns the month in words and year in numbers
function formatDateString(date: string): string {
  const [year] = date.split('-')
  // eslint-disable-next-line prefer-destructuring
  let month = date.split('-')[1]

  month = monthNames[Number.parseInt(month, 10) - 1]

  const dateString = `${month } ${ year}`

  return dateString
}

export default formatDateString
