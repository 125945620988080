import React, {
  useState,
  Fragment,
  useEffect
} from 'react'

// Utils
import { toKebabCase } from '@/utils'

// Styling
import styles from './PaginationDotGroup.module.scss'

const PaginationDotGroup: React.FC<PaginationDotGroupProps> = ({
  defaultActive = 0,
  active,
  slides,
  isInversed,
  name,
  className = '',
  onSelect = () => {
    console.warn(`Method onSelect is not provided for ${name}`)
  },
  twoItemsPerSlide,
  authors
}) => {
  const uniqueName = toKebabCase(`${name}`)
  const [current, setCurrent] = useState(defaultActive)

  useEffect(() => {
    if (typeof active !== 'undefined') {
      setCurrent(active)
    }
  }, [active])

  function setCurrentIndex(index: number) {
    setCurrent(index)
    onSelect(index)
  }

  return (
    <div
      className={`
      ${styles['pagination-dot-group']}
      ${isInversed ? styles.inversed : ''}
      ${className}
    `}
    >
      {twoItemsPerSlide
        ? (
          slides?.map((_, idx) => {
            if (idx % 2 === 0) {
              return (
                <Fragment key={idx}>
                  <input
                    checked={idx === current}
                    id={`pdg-${uniqueName}-${idx}`}
                    name={`pdg-${uniqueName}`}
                    onChange={() => {
                      setCurrentIndex(idx)
                    }}
                    onClick={() => {
                      setCurrentIndex(idx)
                    }}
                    type="radio"
                    value={idx}
                  />
                  <label
                    className={styles.dot}
                    htmlFor={`pdg-${uniqueName}-${idx}`}
                  />
                </Fragment>
              )
            }
          }))
        : (
          slides?.map((slide, idx) => {
            return (
              <Fragment key={idx}>
                <input
                  checked={idx === current}
                  id={`pdg-${uniqueName}-${idx}`}
                  name={`pdg-${uniqueName}`}
                  onChange={() => {
                    setCurrentIndex(idx)
                  }}
                  onClick={() => {
                    setCurrentIndex(idx)
                  }}
                  type="radio"
                  value={idx}
                />
                <label
                  aria-label={`Slide ${idx + 1}: ${slide}`}
                  className={styles.dot}
                  htmlFor={`pdg-${uniqueName}-${idx}`}
                  title={`Slide ${idx + 1}${authors ? `: ${ authors[idx]}` : ''}`}
                />
              </Fragment>
            )
          })
        )}
    </div>
  )
}

export default PaginationDotGroup
