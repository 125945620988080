import React from 'react'

// Components
import ButtonLink from '@/components/atoms/ButtonLink'
import CardBlog from '@/components/molecules/CardBlog'
import Grid, { Col } from '@/components/meta/Grid'
import Carousel from '@/components/organisms/Carousel'

// Styling
import styles from './SectionBlog.module.scss'

// Helpers
import { useMediaQuery } from '@/hooks'

// Types
export interface SectionBlogProps {
  title?: string
  className?: string
  hasButton?: boolean
  isInversed?: boolean
  cardBlogs: CardBlogProps[]
}

const SectionBlog: React.FC<SectionBlogProps> = ({
  title = 'Some things to chew on',
  hasButton = true,
  className = '',
  isInversed,
  cardBlogs
}) => {
  const isMobile = useMediaQuery('(max-width: 767px)')

  return (
    <section className={`${styles.wrapper} ${className}`}>
      <Grid
        className={`
          ${styles.container}
          ${isInversed ? styles['is-inversed'] : ''}
        `}
        cols={12}
        size="l"
      >
        <Col span={10} start={2}>
          <Carousel
            gap={isMobile ? 24 : 56}
            heading={
              <div>
                <h2 className={`heading-3-xl ${styles.title}`}>
                  {title}
                </h2>
                {
                  hasButton && (
                    <ButtonLink
                      href="/blog"
                      icon="arrow"
                      iconPosition="right"
                      size="l"
                      variation="tertiary"
                    >
                      Discover our blog
                    </ButtonLink>
                  )
                }
              </div>
            }
            headingClassName={styles.heading}
            items={
              cardBlogs.map((blog, idx) => (
                <CardBlog key={idx} {...blog} />
              ))
            }
            itemsPerGroup={2}
            loop
          />
        </Col>
      </Grid>
    </section>
  )
}

export default React.memo(SectionBlog)
