import React from 'react'
// Import Image from 'next/image'
import Link from 'next/link'

// Components
import Avatar from '@/components/atoms/Avatar'
import Tag from '@/components/atoms/Tag'
import LazyImage from '@/components/atoms/LazyImage'

// Utils
import { formatDateString } from '@/utils'

// Styling
import styles from './CardBlog.module.scss'

const CardBlog: React.FC<CardBlogProps> = ({
  title,
  slug,
  categories,
  author,
  date,
  isInversed,
  className = '',
  thumbnail,
  thumbnailLoadingColor,
  wrapper: Wrapper = 'div',
  wrapperProps = {}
}) => {
  return (
    <Wrapper
      className={`
        ${styles['card-blog']}
        ${isInversed ? styles.inversed : ''}
        ${className}
      `}

      {...wrapperProps}
    >
      <Link href={`/blog/${slug}`} scroll={false}>
        <a>
          {thumbnail?.responsiveImage && (
            <div className={styles.wrapper}>
              <LazyImage
                className={styles.thumbnail}
                {...(thumbnailLoadingColor?.hex ? { color: thumbnailLoadingColor.hex } : {})}
                data={thumbnail.responsiveImage}
              />
            </div>
          )}
          <section className={styles.content}>
            <h3 className={`heading-2-xl ${styles.header}`}>{title}</h3>
            <footer>
              {categories && categories.length > 0 && (
                <ul>
                  {categories.map((tag, idx) => (
                    <li key={idx}>
                      <Tag
                        category={tag.category ?? ''}
                        className={styles.tag}
                        isInversed={isInversed}
                      />
                    </li>
                  ))}
                </ul>
              )}
              <p className={`body-s ${styles.bullet}`}>•</p>

              {author && (
                <div className={styles.author}>
                  <Avatar
                    // @ts-expect-error this will be fixes with types
                    image={author.avatar}
                    size="1-xs"
                  />
                  <p className="body-s">{author.name}</p>
                </div>
              )}

              <p aria-hidden="true" className={`body-s ${styles.bullet}`}>•</p>
              <p className="body-s">{date ? formatDateString(date) : ''}</p>
            </footer>
          </section>
        </a>
      </Link>
    </Wrapper>
  )
}

export default CardBlog
