import React, {
  useState,
  useRef,
  useEffect
} from 'react'
import {
  LazyMotion,
  domAnimation,
  m
} from 'framer-motion'

// Components
import PaginationDotGroup from '@/components/atoms/PaginationDotGroup'
import Button from '@/components/atoms/Button'

// Styling
import styles from './Carousel.module.scss'

// Constants
import { durationSlow, ease } from '@/constants/animations'

// Lib
import { groupItems } from '@/utils'
import { IconRotate } from '@/lib/enums'
import { useMediaQuery } from '@/hooks'

// eslint-disable-next-line max-statements
const Carousel: React.FC<CarouselProps> = ({
  arrowSize = 'm',
  arrowVariation = 'secondary',
  authors,
  className = '',
  gap = 24,
  hasCarouselCursor,
  hasDivider,
  heading,
  headingClassName = '',
  hidePrevItem,
  isInversed,
  items,
  itemsPerGroup = 2,
  loop,
  showDots = true,
  showNavigationOnMobile,
  showNextItem,
  showScrollbar
}) => {
  const [activeSlide, setActiveSlide] = useState(0)
  const [x, setX] = useState(0)
  const isTouchDevice = useMediaQuery('(hover: none)')

  const slidesRef = useRef<HTMLUListElement>(null)
  const itemRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const groupedItems: JSX.Element[][] = groupItems(items, itemsPerGroup)

  const touchStartX = useRef<number | null>(null)

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    if (!isTouchDevice) { return }
    touchStartX.current = event.touches[0].clientX
  }

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    if (touchStartX.current === null) { return }

    const touchCurrentX = event.touches[0].clientX
    const touchXDiff = touchStartX.current - touchCurrentX
    // Adjust this value to control swipe sensitivity
    const swipeThreshold = 20

    // If the horizontal swipe distance is greater than the swipeThreshold, prevent scrolling
    if (Math.abs(touchXDiff) > swipeThreshold) {
      event.preventDefault()
    }
  }

  const handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
    if (touchStartX.current === null) { return }

    const touchEndX = event.changedTouches[0].clientX
    const touchXDiff = touchStartX.current - touchEndX

    if (touchXDiff > 0 && touchXDiff > 100) {
      handleButtonClick(1)
    } else if (touchXDiff < 0 && touchXDiff < -100) {
      handleButtonClick(-1)
    }

    touchStartX.current = null
  }

  const handleButtonClick = (change: number) => {
    const preferredIndex = activeSlide + change
    const maxIndex = groupedItems.length - 1

    // Preferred index is too high, setting active slide to the last slide or first slide if loop
    if (preferredIndex > maxIndex) { return setActiveSlide(loop ? 0 : maxIndex) }
    // Preferred index is too low, setting active slide to the first slide or last slide if loop
    if (preferredIndex < 0) { return setActiveSlide(loop ? maxIndex : 0) }

    setActiveSlide(preferredIndex)
  }

  const updatePosition = () => {
    if (wrapperRef.current) {
      const { x: newX } = wrapperRef.current.getBoundingClientRect()
      setX(newX)
    }
  }

  useEffect(() => {
    updatePosition()

    window.addEventListener('resize', updatePosition)

    return () => {
      window.removeEventListener('resize', updatePosition)
    }
  }, [])

  const slideStyling = {
    gap: gap,
    width: `calc(${groupedItems.length * 100}% + ${(groupedItems.length - 1) * gap}px)`,
    gridTemplateColumns: `repeat(${groupedItems.length}, 1fr)`

  } as React.CSSProperties

  return (
    <>
      <header
        className={`
          ${styles.header}
          ${isInversed ? styles.inversed : ''}
          ${headingClassName}
        `}
      >
        {heading}

        {groupedItems.length - 1 > 0 && !(hasCarouselCursor && !isTouchDevice) && (
          <div
            className={`
              ${styles.controls}
              ${showNavigationOnMobile ? styles.mobile : ''}
            `}
          >
            { showDots && (
              <PaginationDotGroup
                active={activeSlide}
                authors={authors}
                className={styles.dots}
                // Generate random string to create IDs per carousel
                isInversed={isInversed}
                name={`carousel-${(Math.random() + 1).toString(36).slice(7)}`}
                onSelect={(index) => { setActiveSlide(index) }}
                slides={groupedItems.map((_, idx) => idx.toString())}
              />
            )}

            <Button
              icon="arrow"
              iconDirection={IconRotate.West}
              isDisabled={!loop && activeSlide === 0}
              isInversed={isInversed}
              label="Previous slide"
              onClick={() => { handleButtonClick(-1) }}
              size={arrowSize}
              variation={arrowVariation}
            />
            <Button
              icon="arrow"
              iconDirection={IconRotate.East}
              isDisabled={!loop && activeSlide === groupedItems.length - 1}
              isInversed={isInversed}
              label="Next slide"
              onClick={() => { handleButtonClick(1) }}
              size={arrowSize}
              variation={arrowVariation}
            />
          </div>
        )}

      </header>
      {hasCarouselCursor && hasDivider && (
        <hr className={`${styles.divider} ${isInversed ? styles.inversed : ''}`} />
      )}
      <div
        className={`
          ${styles.carousel}
          ${className}
          ${showScrollbar ? '' : styles['hide-scrollbar']}
          ${showNavigationOnMobile ? styles.mobile : ''}
          ${(itemsPerGroup === 1 && !showNextItem) ? styles.hidden : ''}
          `
        }
      >
        <LazyMotion features={domAnimation}>
          <div
            className={styles['slides-wrapper']}
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchMove}
            onTouchStart={handleTouchStart}
            ref={wrapperRef}
          >
            <m.ul
              animate={{
                x: `calc(${(activeSlide * -1 / groupedItems.length) * 100}% - ${(activeSlide / groupedItems.length * gap)}px)`
              }}
              className={`${styles.slides} ${showScrollbar ? '' : styles['hide-scrollbar']} ${heading || groupedItems.length - 1 > 0 ? styles['slides-padding-top'] : ''}`}
              ref={slidesRef}
              style={slideStyling}
              transition={{
                duration: durationSlow,
                ease: ease
              }}
            >
              {groupedItems.map((group, idx: number) => {
                return (
                  <li
                    className={`
                      ${styles.group}
                      ${idx === activeSlide ? '' : styles.inactive}
                      ${hidePrevItem && idx < activeSlide ? styles['hidden-slide'] : ''}
                    `}
                    key={idx}
                    style={{
                      gridTemplateColumns: `repeat(${itemsPerGroup}, 1fr)`,
                      gap
                    }}
                  >
                    {group.map((item, itemIdx) => (
                      <div
                        key={itemIdx}
                        ref={itemRef}
                      >
                        {item}
                      </div>
                    ))}
                  </li>
                )
              })}
            </m.ul>

            {hasCarouselCursor && !isTouchDevice && (
              <div
                className={styles['control-wrapper']}
                style={{
                  '--left': `-${x}px`
                } as React.CSSProperties}
              >
                <div
                  className={styles.control}
                  data-carousel-prev={activeSlide !== 0 ? true : undefined}
                  onClick={() => { handleButtonClick(-1) }}
                />
                <div
                  className={styles.control}
                  data-carousel-next={activeSlide < groupedItems.length - 1 ? true : undefined}
                  onClick={() => { handleButtonClick(1) }}
                />
              </div>
            )}
          </div>
        </LazyMotion>
      </div>
    </>
  )
}

export default React.memo(Carousel)
