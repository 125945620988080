import React from 'react'
import { Image } from 'react-datocms'

// Hooks
import { useImageSizes } from '@/hooks'

// Styling
import styles from './Avatar.module.scss'

const Avatar: React.FC<AvatarProps> = ({
  image,
  size = 'm',
  className = '',
  style,
  lazyLoad = true
}) => {
  const {
    src,
    srcSet,
    webpSrcSet
  } = useImageSizes(image.responsiveImage.src ?? '', [
    32,
    48,
    56,
    64,
    80,
    96,
    112,
    128,
    144,
    160
  ], 80)

  const width = React.useMemo(() => {
    switch (size) {
      case 'l':
        return 48
      case '1-xl':
        return 56
      case '2-xl':
        return 64
      case '3-xl':
        return 80
      case 'm':
      default:
        return 32
    }
  }, [size])

  return (
    <div
      className={`${styles.avatar} ${styles[`size-${size}`]} ${className}`}
      style={style}
    >
      <Image
        className={styles.image}
        data={{
          ...image.responsiveImage,
          src,
          srcSet,
          webpSrcSet,
          sizes: `${width}px`
        }}
        intersectionThreshold={0}
        lazyLoad={lazyLoad}
      />
    </div>
  )
}

export default React.memo(Avatar)
